.main-navbar {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    background-color: black !important;
    border: none;
}

.nav-link-color {
    color: white !important;
    text-decoration: none !important;
}

.nav-link-color:hover {
        color: gold !important    
}

.text-color-change:hover {
    color: goldenrod!important;
}

.text-color-change {
    color: mediumspringgreen!important;
}

.links-size {
    /*font-size:120%;*/
    margin-right:25px;
}

.h3-container {
    margin-top:28px;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    padding-top:16px;
}

.remove-bottom {
    /*margin-top: 30px;*/
}

.remove-bottom-right {
    /*margin-top: 22px;*/
}


.main-container {
}


/*#home-link {

    color:aqua;
}*/
#navbar-main {
    background-color: black !important;
}

@media screen and (max-width: 968px) {
    
}