.home-image-poster {
    width:100%;
}

/*home gift cards style*/

/*fade in home page tag with hints*/
.bg-color {
    background-color: #fff !important;
}

/*.bg-color h2 {
    background-color:rgba(255, 255, 255, 0.9);
}*/

.seccond-text {
    /*color:white;*/
    /*background-color: rgba(0, 0, 0, 0.19);*/
}

.margin-main-text-reverse {
    color: black;
    text-shadow: 0px 2px 2px rgba(255,255,255,0.4), 0px 2px 3px rgba(255,255,255,0.1), 0px 2px 3px rgba(255,255,255,0.1);
} 

.margin-main-text {
    /*text-shadow:1px 1px 1px white, 1px 1px 1px white;*/
    color: white;
    text-shadow: 0px 2px 2px rgba(0,0,0,0.4), 0px 2px 3px rgba(0,0,0,0.1), 0px 2px 3px rgba(0,0,0,0.1);
}

.margin-button {
    padding-bottom: 20px !important;
}

.color-change-head {
    color: white;
    /*text-shadow: 2px 2px 2px white;*/
    text-shadow: 0px 2px 2px rgba(0,0,0,0.4),
        0px 2px 3px rgba(0,0,0,0.1),
        0px 2px 3px rgba(0,0,0,0.1);
}

.fh5co-box {
    margin-top: -22% !important;
}

.header-no-promotion {
    height: 400px;
    /*background-color:white;*/
    /* background: url(/images/oven-bread5.jpg)no-repeat fixed; */
    background-size: cover;
    background-position-y:-200px;
}

@media screen and (max-width: 768px) {
    .header-no-promotion {
        height: 500px;
        /* background: url(/images/oven-bread5.jpg)no-repeat fixed; */
        background-size: cover;
        background-position-y: -200px;
    }

    #mobile-head {
        height:1150px!important;
    }
}